<template>
  <v-dialog
    v-bind="$attrs"
    class="modal ma-auto"
    :max-width="$vuetify.breakpoint.smAndDown ? '95%': '40%'"
    :min-height="$vuetify.breakpoint.smAndDown ? '350': '100%'"
    v-on="$listeners"
  >
    <v-card
      class="rounded-lg py-4 mx-auto fluid ma-0"
      width="100%"
      height="100%"
    >
      <v-text-field
        v-model="search"
        outlined
        dense
        class="rounded-lg mx-auto mt-2 mb-0"
        style="width: 85% !important;"
        placeholder="Buscar nota"
        append-icon="mdi-magnify"
        autocomplete="off"
      />
      <v-list-item-group
        v-if="notes.length"
        v-model="noteActivated"
        class="mb-7 mt-0 pa-0 container"
        style="max-height: 200px !important; overflow: auto;"
      >
        <v-list-item
          class="d-flex ma-0 px-10"
          v-for="(note, index) in notes"
          :key="index"
          link
        >
          <p class="container__text pa-0 ma-0 text-truncate">
            {{ note.titulo }}
          </p>
        </v-list-item>
      </v-list-item-group>
      <h4 class="text-center mb-10" v-else>
        No hay notas para referenciar
      </h4>
      <div class="mx-auto d-flex justify-center w-100">
        <v-btn
          class="form__btn form__btn--cancel mr-1"
          @click.capture="$emit('closeSearchModal'); noteActivated=null"
        >
          Cancelar
        </v-btn>
        <v-btn
          type="submit"
          class="form__btn form__btn--copy ml-1"
          :disabled="noteActivated===null"
          v-clipboard:copy="noteCopied"
          v-clipboard:success="onCopy"
        >
          Copiar al portapapeles
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    search: '',
    notes: [],
    noteActivated: null
  }),
  async mounted () {
    await this.fetchNotes()
  },
  watch: {
    async search () {
      const response = await axios.get(`api/admin/notas/notas/busquedas/?search=${this.search}`)
      this.notes = response.data.results
    }
  },
  computed: {
    noteCopied () {
      if (this.noteActivated) {
        return `https://notas.usamedic.app/notas/${this.noteActivated}/`
      } else {
        return ''
      }
    }
  },
  methods: {
    async fetchNotes () {
      const { data } = await axios.get('api/notas/notas/')
      this.notes = data
    },
    onCopy () {
      this.$emit('closeSearchModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.form__btn {
  text-transform: none !important;
  background: #FFF !important;
  border: 1px solid #707070 !important;
  font-size: 16px !important;
  color: #999999;
  box-shadow: none !important;
  font-weight: normal!important;
  &--cancel {
    background: #FFFFFF;
    color: #000000;
  }
  &--copy {
    background: #009EFB !important;
    color: #FFF;
  }
}
.container {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #647485;
  }
}
.container::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
</style>
