<template>
  <div
    class="mb-7 pb-7 rounded-lg"
  >
    <!-- <v-card-text class="px-8 pt-5 pb-1">
      <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Crear nota</h3>
    </v-card-text> -->
    <v-form
      ref="form"
      @submit.prevent="dataValidated"
    >
      <v-card-text class="pa-5 px-8">
        <v-combobox
          v-model="note.temas"
          :items="topics"
          item-text="nombre"
          item-value="id"
          chips
          clearable
          dense
          label="Temas"
          class="mx-3 mb-10"
          multiple
          outlined
          :rules="[rules.required]"
        />
        <v-text-field
          v-model="note.titulo"
          label="Título"
          dense
          outlined
          style="max-width: 80%;"
          append-icon="mdi-square-edit-outline"
          class="mx-auto"
          :rules="[rules.required]"
        />
        <p class="field__text field-width mx-auto">Resumen</p>
        <div
          class="mx-auto mb-5 field-width"
          style="min-height: 100px;"
        >
          <ckeditor
            :editor="editor"
            v-model="note.resumen"
            :config="editorConfig"
          />
        </div>
        <div
          class="d-flex justify-space-between field-width mx-auto"
        >
          <p class="field__text">
            Contenido
          </p>
          <v-btn @click="searchNote=true" icon>
            <v-img
              src="@/assets/images/notes/search-references.svg"
              max-width="17"
            />
          </v-btn>
        </div>
        <div
          class="mx-auto mb-5 field-width"
          style="min-height: 100px;"
        >
          <ckeditor
            :editor="editor"
            v-model="note.cuerpo"
            :config="editorConfig"
            style="height: 250px;"
          />
        </div>
        <div
          class="pa-0 ma-0 d-flex mx-auto field-width"
          style="min-height: 150px;"
        >
          <div class="mx-auto mr-1 flex-grow-1" style="max-width: 50%;">
            <p class="field__text">Referencias</p>
            <ckeditor
              :editor="editor"
              v-model="note.pie_1"
              :config="editorConfig"
              style="width: 50%;"
            />
          </div>
          <div class="mx-auto ml-1 flex-grow-1" style="max-width: 50%;">
            <p class="field__text">Referencias</p>
            <ckeditor
              :editor="editor"
              v-model="note.pie_2"
              :config="editorConfig"
              style="width: 50%;"
            />
          </div>
        </div>
        <div
          class="pa-0 ma-0 d-flex mx-auto field-width"
          style="min-height: 150px;"
        >
          <div class="mx-auto mr-1 flex-grow-1" style="max-width: 50%;">
            <p class="field__text">Referencias</p>
            <ckeditor
              :editor="editor"
              v-model="note.pie_1"
              :config="editorConfig"
              style="width: 50%;"
            />
          </div>
          <div class="mx-auto ml-1 flex-grow-1" style="max-width: 50%;">
            <p class="field__text">Referencias</p>
            <ckeditor
              :editor="editor"
              v-model="note.pie_2"
              :config="editorConfig"
              style="width: 50%;"
            />
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn
          class="form__btn form__btn--cancel mr-1"
          to="/dashboard/notes/"
          exact
        >
          Cancelar
        </v-btn>
        <v-btn
          type="submit"
          class="form__btn form__btn--delete ml-1"
        >
          {{ $route.name === 'NewNote' ? 'Crear nota': 'Guardar' }}
        </v-btn>
      </v-card-actions>
    </v-form>
    <TheNoteSearchModal
      v-model="searchNote"
      @closeSearchModal="searchNote=false"
    />
  </div>
</template>

<script>
import { maxLength, required, percentage, minLength } from '@/utils/rules.js'
import { mapState } from 'vuex'
import TheNoteSearchModal from '@/components/dashboardComponents/TheNoteSearchModal.vue'
// ckeditor configuration
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import UploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter'
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat'
import Image from '@ckeditor/ckeditor5-image/src/image'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import List from '@ckeditor/ckeditor5-list/src/list'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption'
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight'
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote'
import Indent from '@ckeditor/ckeditor5-indent/src/indent'
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock'
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline'

export default {
  components: {
    TheNoteSearchModal
  },
  props: {
    currentNote: {
      type: Object,
      default: () => (
        {
          cuerpo: '',
          id: null,
          pie_1: '',
          pie_2: '',
          resumen: '',
          temas: [],
          titulo: ''
        }
      )
    }
  },
  data: () => ({
    topicsSelected: [],
    note: {
      cuerpo: '',
      id: null,
      pie_1: '',
      pie_2: '',
      resumen: '',
      temas: null,
      titulo: ''
    },
    rules: {
      required,
      maxLength,
      percentage,
      minLength
    },
    editorText: '',
    searchNote: false,
    resumen: '',
    editor: ClassicEditor,
    editorConfig: {
      plugins: [
        EssentialsPlugin,
        BoldPlugin,
        ItalicPlugin,
        LinkPlugin,
        ParagraphPlugin,
        ImageInsert,
        UploadAdapter,
        Heading,
        Autoformat,
        Image,
        ImageCaption,
        ImageStyle,
        ImageToolbar,
        ImageUpload,
        List,
        Alignment,
        Table,
        TableToolbar,
        TableCaption,
        BlockQuote,
        Highlight,
        Indent,
        IndentBlock,
        HorizontalLine
      ],
      toolbar: [
        'bold',
        'italic',
        'link',
        'undo',
        'redo',
        'insertImage',
        'heading',
        // '|',
        'alignment',                                                 // <--- ADDED
        'bulletedList',
        'numberedList',
        // 'uploadImage',
        'insertTable',
        'blockQuote',
        'highlight',
        'outdent',
        'indent',
        'horizontalLine'
      ],
      table: {
        contentToolbar: [
          'toggleTableCaption'
        ]
      },
      image: {
        toolbar: [
          'imageStyle:inline',
          'imageStyle:block',
          'imageStyle:side',
          '|',
          'toggleImageCaption',
          'imageTextAlternative'
        ]
      },
      // The configuration of the editor.
      // link: {
      //   // addTargetToExternalLinks: true,
      //   decorators: {
      //     openInNewTab: {
      //       mode: 'manual',
      //       label: 'Open in a new tab',
      //       attributes: {
      //         target: '_blank'
      //         // rel: 'noopener noreferrer'
      //       }
      //     }
      //   }
      // }
    }
  }),
  computed: {
    ...mapState('notes', ['topics'])
  },
  created () {
    if (this.$route.name === 'UpdateNote') {
      this.note = this.currentNote
    }
  },
  mounted () {
    // console.log(ClassicEditor.builtinPlugins.map(plugin => plugin.pluginName))
  },
  methods: {
    async dataValidated () {
      if (this.$refs.form.validate()) {
        this.$emit('formValidated', this.note)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.field {
  &__text {
    font-size: 16px;
    line-height: 19px;
    color: #67757C;
  }
}
.form__btn {
  text-transform: none !important;
  background: #FFF !important;
  border: 1px solid #707070 !important;
  font-size: 16px !important;
  color: #999999;
  box-shadow: none !important;
  font-weight: normal!important;
  &--cancel {
    background: #FFFFFF;
    color: #000000;
  }
  &--delete {
    background: #009EFB !important;
    color: #FFF;
  }
}

.field-width {
  max-width: 80%;
}

@media (max-width: 960px) {
  .field-width {
    max-width: 100% !important;
  }
}
</style>
